// Screw URL
export const BASE_URL =
    process.env.PREACT_APP_BASE_URL ?? "http://localhost:8080"

// Dozer URL
export const REMOTE_URL =
    process.env.PREACT_APP_REMOTE_URL ?? "http://localhost:3000"

// Page inside dozer pointing to the widget
export const WIDGET_URL =
    process.env.PREACT_APP_WIDGET_URL ?? `${REMOTE_URL}/private/widget`
