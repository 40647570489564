import { useEffect } from "preact/hooks"
import type {
    CheckoutPayload,
    KnownUom,
    Source,
} from "@generated/document-types"
import type { UoM } from "./types"

export enum IncomingMessageType {
    FrameClosed = "1build_frame_closed",
    RateSelected = "1build_rate_selected",
    InitConfirmed = "1build_widget_init_confirmed",
    OpenFrameConfirmed = "1build_widget_open_confirmed",
    EventTriggered = "1build_widget_event_triggered",
    SaveInStorage = "1build_widget_save_in_storage",
    CheckoutHandled = "1build_checkout_handled",
}

export interface InitArgs {
    uoms: UoM[]
}

export interface OnRateSelectedArgs {
    source: Source
    selectedRate?: KnownUom
    selectedLaborRateType: "BASE" | "BURDENED" | undefined
}

export enum ListenerEventName {
    Request = "request",
}

export interface EventTriggeredArgs {
    eventName: ListenerEventName.Request
    data: Record<string, unknown>
}

export enum SaveInStorageKey {
    OnboardingCompletedAt = "1build_onboardingCompletedAt",
}

export interface SaveInStorageArgs {
    key: SaveInStorageKey
    value: string
}

export interface VersionConfirmedArgs {
    version: string
}

export interface IncomingMessageHandler {
    [IncomingMessageType.FrameClosed]: () => void
    [IncomingMessageType.RateSelected]: (args: OnRateSelectedArgs) => void
    [IncomingMessageType.InitConfirmed]: (args: InitArgs) => void
    [IncomingMessageType.OpenFrameConfirmed]: () => void
    [IncomingMessageType.EventTriggered]: (args: EventTriggeredArgs) => void
    [IncomingMessageType.SaveInStorage]: (args: SaveInStorageArgs) => void
    [IncomingMessageType.CheckoutHandled]: (args: CheckoutPayload) => void
}

type IncomingMessageSignature = {
    [K in IncomingMessageType]: {
        type: K
        payload: Parameters<IncomingMessageHandler[K]>[number]
    }
}

export type IncomingMessageWrapper =
    IncomingMessageSignature[keyof IncomingMessageSignature]

export const isValidMessageType = (
    value: string,
): value is IncomingMessageType => {
    const enumValues = Object.values(IncomingMessageType).join(",")

    return enumValues.includes(value)
}

interface UseMessageListenerArgs {
    handlers: IncomingMessageHandler
}

export function useMessageListener({ handlers }: UseMessageListenerArgs): void {
    useEffect(() => {
        window.onmessage = function (
            event: MessageEvent<IncomingMessageWrapper>,
        ) {
            if (!isValidMessageType(event.data.type)) {
                return
            }

            const eventType = event.data.type

            switch (eventType) {
                case IncomingMessageType.FrameClosed:
                case IncomingMessageType.InitConfirmed:
                    handlers[eventType](event.data.payload)
                    break
                case IncomingMessageType.OpenFrameConfirmed:
                    handlers[eventType]()
                    break
                case IncomingMessageType.RateSelected:
                    handlers[eventType](event.data.payload)
                    break
                case IncomingMessageType.EventTriggered:
                    handlers[eventType](event.data.payload)
                    break
                case IncomingMessageType.SaveInStorage:
                    handlers[eventType](event.data.payload)
                    break
                case IncomingMessageType.CheckoutHandled:
                    handlers[eventType](event.data.payload)
                    break
            }
        }

        return () => {
            window.onmessage = null
        }
    }, [handlers])
}
