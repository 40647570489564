import { useEffect, useRef } from "preact/hooks"
import { BASE_URL } from "./config"

const STYLES_URL = `${BASE_URL}/assets/styles.css`

export const useEmbedStyles = (): void => {
    const styles = useRef<HTMLLinkElement>()

    useEffect(() => {
        styles.current = document.createElement("link")

        styles.current.rel = "stylesheet"
        styles.current.type = "text/css"
        styles.current.href = STYLES_URL

        document.head.appendChild(styles.current)

        return () => {
            if (styles.current === undefined) {
                return
            }

            document.head.removeChild(styles.current)
        }
    }, [])
}
