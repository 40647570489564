import { useEffect } from "preact/hooks"
import type {
    UoM,
    WidgetIdentifyParams,
    WidgetInitParams,
    WidgetOpenParams,
} from "./types"
import type { ListenerEventName } from "./useMessageListener"
import type { Product } from "@generated/document-types"

interface UseInjectOneBuildObjectParams {
    onCheckout: (
        params: { products: Product[] },
        callback: (url: string) => void,
    ) => void
    onClose: () => void
    onIdentify: (params: WidgetIdentifyParams) => void
    onInit: (params: WidgetInitParams) => void
    onOpen: (params?: WidgetOpenParams) => void
    onAddEventListener: (
        eventName: ListenerEventName,
        callback: (args: unknown) => unknown,
    ) => void
    onRemoveEventListener: (eventName: ListenerEventName) => void
    uoms: UoM[]
}

function useInjectOneBuildObject({
    onCheckout,
    onClose,
    onIdentify,
    onInit,
    onOpen,
    onAddEventListener,
    onRemoveEventListener,
    uoms,
}: UseInjectOneBuildObjectParams): void {
    useEffect(() => {
        window.onebuild = {
            checkout: onCheckout,
            close: onClose,
            identify: onIdentify,
            init: onInit,
            open: onOpen,
            on: onAddEventListener,
            off: onRemoveEventListener,
            uoms,
        }

        return () => {
            window.onebuild = undefined
        }
    }, [
        onAddEventListener,
        onClose,
        onCheckout,
        onIdentify,
        onInit,
        onOpen,
        onRemoveEventListener,
        uoms,
    ])
}

export default useInjectOneBuildObject
