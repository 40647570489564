import { h } from "preact"
import { useEffect, useRef, useState } from "preact/hooks"
import { IFRAME_ID } from "./constants"
import { OutgoingMessageType, widgetPostMessage } from "./lib/postMessage"
import type { UoM, WidgetOpenParams } from "./types"
import { useEmbedStyles } from "./useEmbedStyles"
import useInjectOneBuildObject from "./useInjectOneBuildObject"
import type { ListenerEventName } from "./useMessageListener"
import {
    IncomingMessageType,
    SaveInStorageKey,
    useMessageListener,
} from "./useMessageListener"
import { WIDGET_URL } from "./config"

const DEFAULT_INTERVAL_TIME_MS = 100

const MAX_OPEN_ATTEMPTS = 20
const MAX_INIT_ATTEMPTS = 20
const ALERT_INTERVAL_TIME_MS = 2000

function App(): preact.JSX.Element | null {
    const [iframeVisible, setIframeVisible] = useState(false)
    const [openParams, setOpenParams] = useState<WidgetOpenParams>()
    const [uoms, setUoms] = useState<UoM[]>()
    const [eventListeners, setEventListeners] = useState<
        {
            eventName: ListenerEventName
            callback: (args: unknown) => unknown
        }[]
    >([])
    const [checkoutCallback, setCheckoutCallback] =
        useState<(url: string) => void>()

    const initConfirmedRef = useRef(false)

    const openIntervalIdRef = useRef<NodeJS.Timer>()
    const initIntervalIdRef = useRef<NodeJS.Timer>()

    const openAttemptsCountRef = useRef(0)
    const initAttemptsCountRef = useRef(0)
    const [alert, setAlert] = useState<"init" | "open">()
    const alertIntervalIdRef = useRef<NodeJS.Timer>()

    useEffect(() => {
        if (alert === undefined) {
            return
        }

        alertIntervalIdRef.current = setInterval(() => {
            widgetPostMessage({
                type: OutgoingMessageType.AlertTriggered,
                payload: {
                    method: alert,
                },
            })
        }, ALERT_INTERVAL_TIME_MS)

        return () => {
            clearInterval(alertIntervalIdRef.current)
        }
    }, [alert])

    useEmbedStyles()

    useInjectOneBuildObject({
        onInit(params) {
            if (initIntervalIdRef.current) {
                // clear the exising interval before overriding the ref
                clearInterval(initIntervalIdRef.current)
            }

            initIntervalIdRef.current = setInterval(() => {
                widgetPostMessage({
                    type: OutgoingMessageType.InitStarted,
                    payload: params,
                })

                if (initAttemptsCountRef.current > MAX_INIT_ATTEMPTS) {
                    setAlert("init")
                    clearInterval(initIntervalIdRef.current)
                    return
                }

                initAttemptsCountRef.current += 1
            }, DEFAULT_INTERVAL_TIME_MS)
        },
        onOpen(params) {
            if (openIntervalIdRef.current) {
                // clear the exising interval before overriding the ref
                clearInterval(openIntervalIdRef.current)
            }

            openIntervalIdRef.current = setInterval(() => {
                if (!initConfirmedRef.current) {
                    return
                }

                setOpenParams(params)

                const { onRateSelected: _, ...paramsToSend } = params ?? {}

                widgetPostMessage({
                    type: OutgoingMessageType.OpenFrameStarted,
                    payload: {
                        ...paramsToSend,
                        storage: {
                            [SaveInStorageKey.OnboardingCompletedAt]:
                                localStorage.getItem(
                                    SaveInStorageKey.OnboardingCompletedAt,
                                ),
                        },
                    },
                })

                if (openAttemptsCountRef.current > MAX_OPEN_ATTEMPTS) {
                    setAlert("open")
                    return
                }

                openAttemptsCountRef.current += 1
            }, DEFAULT_INTERVAL_TIME_MS)
        },
        onIdentify(params) {
            widgetPostMessage({
                type: OutgoingMessageType.IdentifyStarted,
                payload: params,
            })
        },
        onClose() {
            if (!iframeVisible) {
                return
            }

            widgetPostMessage({
                type: OutgoingMessageType.CloseFrameStarted,
            })
        },
        onAddEventListener(eventName, callback) {
            setEventListeners((prev) => [...prev, { eventName, callback }])
        },
        onRemoveEventListener(eventName) {
            setEventListeners((prev) =>
                prev.filter((listener) => listener.eventName !== eventName),
            )
        },
        uoms: uoms ?? [],
        onCheckout(params, callback) {
            setCheckoutCallback(() => callback)
            widgetPostMessage({
                type: OutgoingMessageType.CheckoutStarted,
                payload: params,
            })
        },
    })

    useMessageListener({
        handlers: {
            [IncomingMessageType.FrameClosed]() {
                setIframeVisible(false)
            },
            [IncomingMessageType.RateSelected](args) {
                openParams?.onRateSelected?.(args)

                if (openParams?.closeOnRateSelected !== false) {
                    widgetPostMessage({
                        type: OutgoingMessageType.CloseFrameStarted,
                    })
                }
            },
            [IncomingMessageType.OpenFrameConfirmed]() {
                setIframeVisible(true)
                clearInterval(openIntervalIdRef.current)

                if (alert !== "open") {
                    return
                }

                setAlert(undefined)
                clearInterval(alertIntervalIdRef.current)
            },
            [IncomingMessageType.InitConfirmed](args) {
                setUoms(args.uoms)
                initConfirmedRef.current = true
                clearInterval(initIntervalIdRef.current)

                if (alert !== "init") {
                    return
                }

                setAlert(undefined)
                clearInterval(alertIntervalIdRef.current)
            },
            [IncomingMessageType.EventTriggered](args) {
                eventListeners.forEach(({ eventName, callback }) => {
                    if (eventName === args.eventName) {
                        callback(args.data)
                    }
                })
            },
            [IncomingMessageType.SaveInStorage](args) {
                const { key, value } = args

                localStorage.setItem(key, value)
            },
            [IncomingMessageType.CheckoutHandled](args) {
                const { url } = args

                if (!checkoutCallback) {
                    return
                }

                checkoutCallback(url)
            },
        },
    })

    return (
        <div id="preact_root">
            {Boolean(iframeVisible) && (
                <div
                    style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        height: "100vh",
                        width: "99vw",
                    }}
                    onClick={() => {
                        widgetPostMessage({
                            type: OutgoingMessageType.CloseFrameStarted,
                        })
                    }}
                />
            )}
            {
                <iframe
                    allow="geolocation"
                    className={`${IFRAME_ID} ${iframeVisible ? "open" : ""}`}
                    // We set this style here to prevent the "flashing" of the iframe
                    // when it's loading the assets
                    style={{
                        height: "0px",
                        width: "0px",
                        boxShadow: "unset",
                    }}
                    id={IFRAME_ID}
                    loading="eager"
                    src={WIDGET_URL}
                    title={IFRAME_ID}
                />
            }
        </div>
    )
}

export default App
