import type { SaveInStorageKey } from "src/useMessageListener"
import { IFRAME_ID } from "../constants"
import type {
    WidgetIdentifyParams,
    WidgetInitParams,
    WidgetOpenParams,
} from "../types"
import type { Product } from "@generated/document-types"

export enum OutgoingMessageType {
    IdentifyStarted = "1build_widget_identify_started",
    InitStarted = "1build_widget_init_started",
    OpenFrameStarted = "1build_open_frame_started",
    CloseFrameStarted = "1build_close_frame_started",
    AlertTriggered = "1build_alert_triggered",
    CheckoutStarted = "1build_checkout_started",
}

export type OutgoingMessageWrapper =
    | {
          type: OutgoingMessageType.IdentifyStarted
          payload: WidgetIdentifyParams
      }
    | {
          type: OutgoingMessageType.InitStarted
          payload: WidgetInitParams
      }
    | {
          type: OutgoingMessageType.OpenFrameStarted
          payload?: WidgetOpenParams & {
              storage: Record<SaveInStorageKey, string | null>
          }
      }
    | {
          type: OutgoingMessageType.CloseFrameStarted
      }
    | {
          type: OutgoingMessageType.AlertTriggered
          payload: {
              method: "open" | "init"
          }
      }
    | {
          type: OutgoingMessageType.CheckoutStarted
          payload: {
              products: Product[]
          }
      }

export function widgetPostMessage(args: OutgoingMessageWrapper): void {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const iframe = document.getElementById(IFRAME_ID) as HTMLIFrameElement

    iframe.contentWindow?.postMessage(args, "*")
}
