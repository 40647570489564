import App from "./App"

const existingElement = document.getElementById("preact_root")

if (existingElement === null) {
    const element = document.createElement("div")
    document.body.prepend(element)
}

export default App
